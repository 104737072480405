@font-face {
  font-family: "belgiano";
  src: local("belgiano"), url("./assets/fonts/belgiano.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "articulat";
  src: local("articulat"), url("./assets/fonts/articulat.otf") format("truetype");
  font-weight: 300;
}

body {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.root{
  width: 100%;
  height: 100%;
}
